<template>
    <div class="videobg__cont">
        <div
            class="videobg transition"
            id="videobg"
        />
        <InViewTrigger
            class="videobg__intersect"
            @intersectChange="onIntersectChange"
            :margin="100"
        />
    </div>
</template>

<script>
export default {
    name: 'VideoBG',
    components: {
        InViewTrigger: () => import('../helpers/InViewTrigger.vue'),
    },
    props: {
        type: {
            type: String,
            default: 'youtube',
        },
        src: {
            type: String,
            required: true,
        },
        startSeconds: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            cleanSrc: '',
            visible: false,
            player: false,
        };
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
        this.initScript();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        if (this.player) {
            this.player.destroy();
        }
    },
    methods: {
        initScript() {
            this.getCleanSrc();
            if (!window.YT) {
                const script = document.createElement('script');
                script.setAttribute('src', 'https://www.youtube.com/player_api');
                document.head.appendChild(script);

                window.onYouTubeIframeAPIReady = this.setupPlayer;
            } else {
                this.setupPlayer();
            }
        },

        getCleanSrc() {
            const youtube_parser = (url) => {
                const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                const match = url.match(regExp);
                return (match && match[7].length === 11) ? match[7] : false;
            };

            if (this.type === 'youtube' && (this.src.startsWith('http') || this.src.startsWith('www'))) {
                this.cleanSrc = youtube_parser(this.src);
            } else {
                this.cleanSrc = this.src;
            }
        },

        setupPlayer() {
            const playerDefaults = {
                autoplay: 0, autohide: 1, modestbranding: 0, rel: 0, showinfo: 0, controls: 0, disablekb: 1, enablejsapi: 0, iv_load_policy: 3,
            };
            this.player = new window.YT.Player('videobg', { events: { onReady: this.onPlayerReady, onStateChange: this.onPlayerStateChange }, playerVars: playerDefaults });
        },

        onPlayerReady() {
            this.player.cueVideoById({
                videoId: this.cleanSrc,
                startSeconds: this.startSeconds,
            }); // also supports endSeconds
            this.playVideo();
        },

        playVideo() {
            if (this.visible && this.player && typeof this.player.playVideo === 'function') {
                try {
                    this.onResize();
                    this.player.mute();
                    this.player.playVideo();
                } catch (err) {
                    //
                }
            }
        },

        pauseVideo() {
            if (this.player && typeof this.player.pauseVideo === 'function') {
                this.player.pauseVideo();
            }
        },

        onPlayerStateChange(e) {
            if (e.data === 1) {
                this.$el.getElementsByClassName('videobg')[0].style.opacity = 1;
            } else if (e.data === 0) {
                this.$el.getElementsByClassName('videobg')[0].style.opacity = 0;
                this.onPlayerReady();
            }
        },

        onResize() {
            const w = this.$el.offsetWidth + 200;
            const h = this.$el.offsetHeight + 200;

            if (w / h > 16 / 9) {
                if (this.player) {
                    this.player.setSize(w, (w / 16) * 9);
                }
                this.$el.getElementsByClassName('videobg')[0].style.left = 0;
            } else {
                if (this.player) {
                    this.player.setSize((h / 9) * 16, h);
                }
                this.$el.getElementsByClassName('videobg')[0].style.left = -1 * ((this.$el.offsetWidth - w) / 2);
            }
        },

        onIntersectChange(visible) {
            this.visible = visible;
            if (this.player) {
                if (this.visible) {
                    this.playVideo();
                } else {
                    this.pauseVideo();
                }
            }
        },
    },
};
</script>

<style>
.videobg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: auto;
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
}
    .videobg__cont {
        overflow: hidden;
    }
    .videobg__intersect {
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
    }
</style>
